import React, { Component } from 'react';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import { Translate } from "react-localize-redux";

import ProductSlide from './ProductSlide/ProductSlide';
import nextBtnImage from '../../../../resources/next-arrow-white.png';
import DotNavigation from '../../../UI/DotNavigation/DotNavigation';

import classes from './Portfolio.module.css';

class ProductPortfolio extends Component {
    state = {
        currentSlide: 1
    }

    responsive = {
        0: { items: 1 },
    };

    changeSlideHandler = (index) => {
        if(index >= this.props.products.length) return;
        this.setState({currentSlide: index});
    }

    onSlideChanged = (e) => {
        // console.log('Item`s position after changes: ', e.item);
        // console.log('Slide`s position after changes: ', e.slide);
        this.setState({ currentSlide: e.item });
    };


    galleryItems() {
        return (
            this.props.products.map((product, i) => {
                return (
                    <ProductSlide product={product} index={i} key={product.slug} currentLanguage={this.props.currentLanguage}/>
                )
            })
        )
    };

    render() {
        const items = this.galleryItems();

        const currentProduct = this.props.products[this.state.currentSlide];
        const colors = {
            top: currentProduct.color.top,
            bottom: currentProduct.color.bottom,
        };

        return (
            <div
                className={classes.PortfolioContainer}
                style={{ backgroundImage: 'linear-gradient(to bottom,' + colors.top + ',' + colors.bottom + ')' }}
            >
                <h2><Translate id="aboutUsPage.portfolio" /></h2>
                <div className={classes.CarouselContainer}>
                    <AliceCarousel
                        className={classes.Carousel}
                        items={items}
                        startIndex={this.state.currentSlide}
                        // fadeOutAnimation={true}
                        mouseDragEnabled={true}
                        responsive={this.responsive}
                        // onSlideChange={this.onSlideChange}
                        onSlideChanged={this.onSlideChanged}
                        buttonsDisabled={true}
                        dotsDisabled={true}
                        keysControlDisabled={true}
                        ref={el => this.Carousel = el}
                    />
                    <img src={nextBtnImage} alt="arrow" className={classes.PrevButton} onClick={() => this.Carousel._slidePrev()} />
                    <img src={nextBtnImage} alt="arrow" className={classes.NextButton} onClick={() => this.Carousel._slideNext()} />
                </div>
                <div className={classes.DotNavigationContainer}>
                    <DotNavigation 
                        allItems={this.props.products.length} 
                        selectedItem={this.state.currentSlide}
                        clickDot={this.changeSlideHandler} />
                </div>
            </div>
        );
    }
}

export default ProductPortfolio;