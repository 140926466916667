import React, { Component } from 'react';
import YoutubeEmbedVideo from 'youtube-embed-video';
import { HashLink } from 'react-router-hash-link';

import CloudBackground from './CloudBackground/CloudBackground';
import VideoThubnail from './VideoThumbnail/VideoThumbnail';
import SeeMoreButton from './SeeMoreButton/SeeMoreButton';
import DotNavigation from '../../../UI/DotNavigation/DotNavigation';
import toiletThumbnailBackground from '../../../../resources/video-screenshot.png';
import kitchenThumbnailBackground from '../../../../resources/kitchen_paper_thumbnail.png';

import classes from './VideoSection.module.css';


class VideoSection extends Component {
    state = {
        videos: [
            {
                thumbnail: toiletThumbnailBackground,
                id: 'biR5-HpCC4I'
            },
            {
                thumbnail: kitchenThumbnailBackground,
                id: '8HQuEv0vvok'
            },  

        ],
        activeVideo: {
            thumbnail: toiletThumbnailBackground,
            id: 'biR5-HpCC4I'
        },
        activeVideoIndex: 0,
        playVideo: false
    }

    playVideoHandler = () => {
        this.setState({playVideo: true});
    }

    stopVideoHandler = () => {
        this.setState({playVideo: false});
    }

    changeVideoHandler = (index) => {
        this.stopVideoHandler();
        const newVideo = this.state.videos[index];
        this.setState({activeVideo: newVideo, activeVideoIndex: index});
    }

    render() {

        const video = this.state.playVideo ? (
            <YoutubeEmbedVideo videoId={this.state.activeVideo.id} suggestions={false} width='100%' height='100%' autoplay/>
        ) : <VideoThubnail clickPlay={this.playVideoHandler} thumbnailBG={this.state.activeVideo.thumbnail} videoId={this.state.activeVideo.id}/>
        
        const seeMoreBtn = this.props.seeMoreBtn ? (
            <HashLink to={this.props.seeMoreBtn} smooth>
                <div className={classes.SeeMoreButtonContainer + ' ' + classes.DesktopOnly} ><SeeMoreButton /></div>
            </HashLink>

        ) : null;

        const customDotStyles = {
            selectedDot: {
                backgroundColor: 'rgba(64, 60, 119, 0.8)',
                height: '10px',
                width: '10px'
            },
            notSelectedDot: {
                backgroundColor: 'rgba(64, 60, 119, 0.4)',
                height: '10px',
                width: '10px'
            }
        }

        return (
            <CloudBackground id={this.props.id}>
                <div className={classes.VideoBox}>
                    {video}
                    {seeMoreBtn}
                </div>
                <div className={classes.DotNavigationContainer}>
                    <DotNavigation 
                        allItems={this.state.videos.length} 
                        selectedItem={this.state.activeVideoIndex}
                        clickDot={this.changeVideoHandler}
                        customDots={customDotStyles} />
                </div>
            </CloudBackground>
        );
    }
};

export default VideoSection;