import React from 'react';

import classes from './TropicalButterfly.module.css';

const tropicalButterfly = (props) => {
    return (
        <div className={classes.content}>
            <div className={classes.butterfly}>
                <div className={classes.wing}>
                    <div className={classes.bit}></div>
                    <div className={classes.bit}></div>
                </div>
                <div className={classes.wing}>
                    <div className={classes.bit}></div>
                    <div className={classes.bit}></div>
                </div>
            </div>

            <div className={classes.shadow}></div>
        </div>
    );
}

export default tropicalButterfly;