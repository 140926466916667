import React from 'react';
import { Link } from 'react-router-dom';
import { Translate } from "react-localize-redux";

import classes from './CarouselItem.module.css';

const carouselItem = (props) => {
    
    const product = props.product;
    
    const productImage = product.sizes[0] ? product.sizes[0].image.secure_url : 'notexistentImage';
    const color = product.color.top ? product.color.top : '#fff';
    const title = product.title[props.currentLanguage] ? product.title[props.currentLanguage] : null;
    const description = product.description[props.currentLanguage] ? product.description[props.currentLanguage] : null;

    return (
        <Link to={'/products/' + product.slug} style={{textDecoration: 'none'}}>
            <div key={product.slug} className={classes.Item} style={{borderTop: "4px solid " + color}}>
                <img src={productImage} alt={title}/>
                <h3 style={{color: color}}>{title}</h3>
                <p>{description}</p>
                <div className={classes.Link} style={{color: color}}><Translate id='homePage.learnMore' /> +</div>
            </div>
        </Link> 
    );
}

export default carouselItem;