import React, { Component } from 'react';
import axios from '../../axiosConfig';
import { Translate } from "react-localize-redux";

import TimelineElement from '../../components/Pages/AboutUsPage/HistorySection/TimelineElement/TimelineElement';

import classes from './History.module.css';

class History extends Component {
    state = {
        stories: [
            {
                slug: "partnership-with-zebra",
                content: {
                    BG: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lobortis sapien vel sapien tincidunt, lacinia pellentesque ipsum pharetra. Praesent rhoncus in diam tristique vestibulum. Pellentesque tempor neque ac consequat congue. Quisque dictum fringilla magna accumsan egestas. Nunc sed nulla vel libero eleifend porttitor eu a magna. Curabitur in efficitur ante. Phasellus vel vestibulum odio, ut accumsan est.</p>",
                    EN: "<p>Praesent rhoncus in diam tristique vestibulum. Pellentesque tempor neque ac consequat congue. Quisque dictum fringilla magna accumsan egestas. Nunc sed nulla vel libero eleifend porttitor eu a magna. Curabitur in efficitur ante. Phasellus vel vestibulum odio, ut accumsan est.</p>"
                },
                title: {
                    BG: "Приятели със Зебра",
                    EN: "Friendship with Zebra"
                }
            },
            {
                slug: "between-start-and-zebra",
                content: {
                    BG: "<p>Aliquam quis ornare felis, a sodales mi. Fusce vel justo diam. Maecenas laoreet viverra porta. Etiam in lorem non metus aliquet tincidunt. Nulla pellentesque nisl ipsum, sed aliquet libero imperdiet vel. Aenean ut tortor et risus convallis elementum. Mauris sit amet risus in sem molestie vulputate sit amet eu nulla. Etiam posuere et sem in placerat. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Pellentesque ut neque ut dui pulvinar tempor nec nec nisi.</p>",
                    EN: "<p>Fusce vel justo diam. Maecenas laoreet viverra porta. Etiam in lorem non metus aliquet tincidunt. Nulla pellentesque nisl ipsum, sed aliquet libero imperdiet vel. Aenean ut tortor et risus convallis elementum. Mauris sit amet risus in sem molestie vulputate sit amet eu nulla. Etiam posuere et sem in placerat. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Pellentesque ut neque ut dui pulvinar tempor nec nec nisi.</p>"
                },
                title: {
                    BG: "Междувременно...",
                    EN: "Between start and zebra"
                }
            },
            {
                slug: "beginning-in-2008",
                content: {
                    BG: "<p>Историята за началото на малива на български</p>",
                    EN: "<p>Lorem ipsum dummy content for the story</p>"
                },
                title: {
                    BG: "Началото през 2008",
                    EN: "The beginning in 2008"
                }
            },
        ],
        loading: true
    }

    async componentDidMount () {
        const storiesArray = await this.fetchHistory();
        if(storiesArray.length > 0) this.setState({stories: storiesArray}, () => {this.stopLoading()});
    }

    async fetchHistory () {
        this.setState({loading: true});
        try {
            const storiesResponse = await axios.get('api/history');
            
            const data = storiesResponse.data;
            const storiesArray = [];
            for(const key in data) {
                storiesArray.push({...data[key]});
            }
            return storiesArray;
        } catch {
            this.setState({loading: false});
            return [];
        }
    }

    stopLoading = () => {
        this.setState({loading: false});
    }

    render() {

        const elements = this.state.stories.map((story, index) => (
            <TimelineElement 
                title={story.title[this.props.currentLanguage]} 
                content={story.content[this.props.currentLanguage]}
                left={index % 2 === 0}
                key={index}
                last={index === (this.state.stories.length - 1)}
            />
        ));

        return (
            <div className={classes.HistorySection} id='history'>
                <h2 className={classes.SectionTitle}><Translate id="aboutUsPage.historyTitle" /></h2>
                <div className={classes.HistoryContent}>
                    {elements}
                </div>
            </div>
        );
    }
}

export default History;