import React from 'react';

import timelineDotImg from '../../../../../resources/time-line-dot.png';

import classes from './TimelineElement.module.css';

const createMarkup = (content) => {
    // const content = this.state.content[this.props.currentLanguage]
    return {__html: content}
}

const timelineElement = (props) => {
    const timelineClasses = [classes.TimelineElement];
    if(props.left) timelineClasses.push(classes.Left);

    const lineAndDot = props.last ? (
        <img className={classes.OnlyTimelineDot} src={timelineDotImg} alt='timeline dot' />
    ) : (
        <div className={classes.LineAndDot}>
            <img className={classes.TimelineDot} src={timelineDotImg} alt='timeline dot' />
        </div>
    )

    return (
        <div className={timelineClasses.join(' ')}>
            {lineAndDot}
            <div className={classes.ElementContent}>
                <h3>{props.title}</h3>
                {/* <p>{props.content}</p> */}
                <div dangerouslySetInnerHTML={createMarkup(props.content)} />
            </div>
        </div>
    );
}

export default timelineElement;