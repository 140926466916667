import React from 'react';
import Spinner from '../../../../UI/Spinner/Spinner';
import { Translate } from "react-localize-redux";

import Tab from './Tab/Tab';
import grayMessengerIcon from '../../../../../resources/messanger-icon-gray.png';

import classes from './TabsBar.module.css';

const tabsBar = (props) => {
    const tabs = props.loading ? <Spinner /> : props.categories.map(category => {
        const active = category.slug === props.activeCategory.slug;
        return (
            <Tab key={category.slug} clicked={() => props.changeCategory(category)} active={active}>
                {category.title[props.currentLanguage]}
            </Tab>
        );
    })

    const contactUsLink = props.showContactUsLink ? (
        <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/MalivaEU/">
            <div className={classes.ContactUsLink}>
                <Translate id='productsPage.connect'/> 
                <img src={grayMessengerIcon} alt='messenger icon' />
            </div>
        </a>
    ) : null;

    return (
        <div className={classes.TabsBar}>
            <div className={classes.TabsContainer}>
                {tabs}
            </div>
            {contactUsLink}
        </div>
    );
}

export default tabsBar;