import React from 'react';

import selectedDotImg from '../../../resources/selected-carousel-dot.png';
import notSelectedDotImg from '../../../resources/not-selected-carousel-dot.png';

import classes from './DotNavigation.module.css';

const dotNavigation = (props) => {
    const dotClasses = [classes.Dot];
    if(props.clickDot) dotClasses.push(classes.Clickable);
    if(props.customDots) dotClasses.push(classes.CustomDot);
    
    const allDots = [];

    if(props.customDots) {
        for (let i = 0; i < props.allItems; i++) {
            if(i === props.selectedItem) {
                allDots.push(
                    <div 
                        key={i} 
                        className={[classes.Dot, classes.CustomDot].join(' ')}
                        style={props.customDots.selectedDot}
                    />
                )
            }
            else allDots.push(
                <div 
                    key={i} 
                    onClick={props.clickDot ? () => props.clickDot(i) : null}
                    className={dotClasses.join(' ')}
                    style={props.customDots.notSelectedDot}
                />
            );
        }
    } else {
        for (let i = 0; i < props.allItems; i++) {
            if(i === props.selectedItem) allDots.push(<img key={i} className={classes.Dot} src={selectedDotImg} alt='selected dot'/>)
            else allDots.push(<img 
                key={i} 
                onClick={props.clickDot ? () => props.clickDot(i) : null}
                className={dotClasses.join(' ')} 
                src={notSelectedDotImg} 
                alt='unselected dot' />);
        }
    }

    const printDots = allDots.map(item => item);
    
    return (
        <div className={classes.DotNavigation} style={props.customStyle}>
            {printDots}
        </div>
    );
}

export default dotNavigation;