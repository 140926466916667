import React from 'react';
import { Link } from 'react-router-dom';
import { Translate } from 'react-localize-redux';

import DotNavigation from '../../../../UI/DotNavigation/DotNavigation';
import toiletPictogramImg from '../../../../../resources/sizes-pictogram.png';
import kitchenPictogramImg from '../../../../../resources/sizes-pictogram-kitchen.png';
import napkinsPictogramImg from '../../../../../resources/sizes-pictogram-napkins.png';
import layersIcon from '../../../../../resources/layers-icon.png';
import easyOpenIcon from '../../../../../resources/easy-open-icon.png';
import nextBtnImage from '../../../../../resources/next-arrow-white.png';
import SizeModule from './SizeModule/SizeModule';

import classes from './SingleProductBanner.module.css';

const singleProductBanner = (props) => {

    const product = props.product;
    const currentLanguage = props.currentLanguage;

    const colors = {
        top: product.color.top,
        bottom: product.color.bottom
    }
    const image = product.image.secure_url;
    const title = product.title[currentLanguage] ? product.title[currentLanguage] : null;
    const easyOpen = product.easyOpen;

    // console.log(colors);

    const sizes = product.sizes.map(size => (
        <SizeModule 
            key={size._id} 
            size={size}
            color={colors.top} 
            currentLanguage = {currentLanguage}/>))
    
    const easyOpenTag = easyOpen ? (<div className={classes.LayersIconContainer} >
            <img src={easyOpenIcon} alt="easy open icon" />
            <div className={classes.LayersIconText}>
                <p><Translate id="productsPage.technology" /></p>
                <p>Easy Open</p>
            </div>
        </div>) : null;

    let pictogram = toiletPictogramImg;

    let layers = (
        <div className={classes.LayersIconContainer} style={{marginRight: '30px'}}>
            <img src={layersIcon} alt="layers icon" />
            <div className={classes.LayersIconText}>
                <p><Translate id="productsPage.threeLayer" /></p>
                <p><Translate id="productsPage.toilet" /></p>
                <p><Translate id="productsPage.paper" /></p>
            </div>
        </div>
    );

    if(props.activeCategory.slug === 'kitchen-paper') {
        pictogram = kitchenPictogramImg;
        layers = (
            <div className={classes.LayersIconContainer} style={{marginRight: '30px'}}>
                <img src={layersIcon} alt="layers icon" />
                <div className={classes.LayersIconText}>
                    <p><Translate id="productsPage.threeLayer" /></p>
                    <p><Translate id="productsPage.kitchen" /></p>
                    <p><Translate id="productsPage.paper" /></p>
                </div>
            </div>
        );
    }
    else if(props.activeCategory.slug === 'napkins') {
        pictogram = napkinsPictogramImg
        layers = null;
    };

    return (
        <div className={classes.BannerWithModules} >
            <div 
                className={classes.SingleProductBanner} 
                style={{ backgroundImage: 'linear-gradient(to bottom,' + colors.top + ',' + colors.bottom + ')' }}
                >
                <div className={classes.Content} >
                    <div className={classes.ImageContainer}>
                        <img src={image} alt="product" />
                    </div>
                    <div className={classes.BannerText}>
                        <h2>{title}</h2>
                        <div className={classes.PictogramContainer}>
                            <img src={pictogram} alt="sizes pictogram" />
                            <div className={classes.PictogramText}>
                                <p><Translate id="productsPage.cellulose" /></p>
                                <p><Translate id="productsPage.modernAndPrecise" /></p>
                            </div>
                        </div>
                        <div className={classes.TechImgContainer}>
                            {layers}
                            {easyOpenTag}
                        </div>
                    </div>
                    <Link to={'/products/' + props.neighbours.prev} ><img src={nextBtnImage} alt="arrow" className={classes.PrevButton} /></Link>
                    <Link to={'/products/' + props.neighbours.next} ><img src={nextBtnImage} alt="arrow" className={classes.NextButton} /></Link>
                </div>
                <DotNavigation allItems={props.allProducts} selectedItem={props.index} />
            </div>
            <div className={classes.SizesContainer}>
                {sizes}
            </div>
        </div>
        
    );
}

export default singleProductBanner;