import React, { Component } from 'react';
import axios from '../../axiosConfig';
import { Translate } from "react-localize-redux";

import CloudBackground from '../../components/Pages/HomePage/VideoSection/CloudBackground/CloudBackground';
import Input from '../../components/UI/Input/Input';
import submitArrowImg from '../../resources/send-email-button-arrow.png';
import Spinner from '../../components/UI/Spinner/Spinner';

import classes from './ContactData.module.css';

class ContactData extends Component {
    state = {
        contactInfo: {
            name: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    // required: true
                },
                value: '',
                required: true,
                valid: true,
                err: 'nameErrMessage',
                placeholder: {
                    BG: 'Вашите Имена*',
                    EN: 'Your Names*'
                }
            },
            email: {
                elementType: 'input',
                elementConfig: {
                    type: 'email',
                    // required: true
                },
                value: '',
                valid: true,
                required: true,
                err: 'emailErrMessage',
                placeholder: {
                    BG: 'E-mail Адрес*',
                    EN: 'E-mail Address*'
                }
            },
            phone: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                },
                value: '',
                valid: true,
                placeholder: {
                    BG: 'Телефон за контакт',
                    EN: 'Phone number'
                }
            },
        },
        content: {
            elementType: 'textarea',
            elementConfig: {
                style: {height: '150px'},
                // required: true
            },
            value: '',
            valid: true,
            required: true,
            err: 'contentErrMessage',
            placeholder: {
                BG: '\n\nВашето съобщение...',
                EN: '\n\nYour message...'
            }
        },
        loading: false,
        messageSent: false
    }

    inputChangedHandler = (event, inputIdentifier) => {
        if(inputIdentifier === 'content') {
            const updatedContent = {...this.state.content};
            updatedContent.value = event.target.value;
            this.setState({content: updatedContent});
            this.setState({messageSent: false});
            return;
        }

        const updatedContactInfo = {...this.state.contactInfo};
        const updatedContactElement = {...updatedContactInfo[inputIdentifier]};
        updatedContactElement.value = event.target.value;
        updatedContactInfo[inputIdentifier] = updatedContactElement;
        this.setState({contactInfo: updatedContactInfo});
        this.setState({messageSent: false});
    }

    sanitizeInput = (inputIdentifier) => {
        if(inputIdentifier === 'content') {
            const updatedContent = {...this.state.content};
            updatedContent.value = this.state.content.value.trim();
            if(!updatedContent.value) updatedContent.valid = false;
            else updatedContent.valid = true;
            this.setState({content: updatedContent}, () => console.log(this.state.content.valid));
            return;
        }

        const updatedContactInfo = {...this.state.contactInfo};
        const updatedContactElement = {...updatedContactInfo[inputIdentifier]};
        updatedContactElement.value = this.state.contactInfo[inputIdentifier].value.trim();
        if(updatedContactElement.required && !updatedContactElement.value) updatedContactElement.valid = false;
        else updatedContactElement.valid = true;
        if(updatedContactElement.elementConfig.type === 'email' && updatedContactElement.required) updatedContactElement.valid = this.isValidEmail(updatedContactElement.value);
        updatedContactInfo[inputIdentifier] = updatedContactElement;
        this.setState({contactInfo: updatedContactInfo}, () => console.log(this.state.contactInfo[inputIdentifier].valid));
    }

    submitFormHandler = (event) => {
        event.preventDefault();
        let isFormValid = this.state.content.valid;
        for(var key in this.state.contactInfo) {
            isFormValid = isFormValid && this.state.contactInfo[key].valid;
        }
        if(isFormValid) {
            this.setState({loading: true});
            const formData = {};
            for(let formElementIdentifier in this.state.contactInfo) {
                formData[formElementIdentifier] = this.state.contactInfo[formElementIdentifier].value;
            }
            formData.message = this.state.content.value;

            axios.post('api/enquiry', formData)
                .then(response => {
                    console.log(response);
                    this.setState({loading: false, messageSent: true});
                })
                .catch(err => {
                    this.setState({loading: false});
                    console.log(err);
                });
                
            console.log(formData);
        }
        else console.log('form is invalid');
    }

    isValidEmail = (email) => {
        if(typeof email !== 'string') return false;

        let isValid = true;
        if(email[0] === '@' ) isValid = false;
        if(email[email.length - 1] === '@') isValid = false;
        if(!email.includes('@')) isValid = false;
        const splittedEmail = email.split('@');
        if(splittedEmail.length !== 2) isValid = false;
        else {
            const domainPart = splittedEmail[1];
            if(!domainPart.includes('.')) isValid = false;
            return isValid;
        }
    }
    
    render () {

        const contactInfoInputs = [];
        for (const key in this.state.contactInfo) {
            contactInfoInputs.push({
                id: key,
                config: this.state.contactInfo[key]
            });
        }

        const printContactInfoInputs = contactInfoInputs.map(field => (
            <Input 
                key={field.id}
                elementType={field.config.elementType}
                elementConfig={field.config.elementConfig}
                value={field.config.value}
                elementPlaceholder={field.config.placeholder}
                changed={(event) => this.inputChangedHandler(event, field.id)}
                blurred={() => this.sanitizeInput(field.id)} 
                currentLanguage={this.props.currentLanguage} 
                err = {!field.config.valid ? field.config.err : null}/>
        ))

        const contentInput = this.state.content;
        
        let button = (<button className={classes.SubmitButton} form='contactForm'>
                        <Translate id="contactUsPage.sendBtn" /> 
                        <img src={submitArrowImg} alt="icon" />
                    </button>);

        if(this.state.loading) button = <Spinner />;
        if(this.state.messageSent) button = <h3><Translate id="contactUsPage.successMessage" /></h3>

        return (
            <CloudBackground>
                <div className={classes.FormContainer} id='contact-form'>
                    <h2><Translate id="contactUsPage.contactTitle" /></h2>
                    <form onSubmit={this.submitFormHandler} id='contactForm'>
                        <div className={classes.ContactInfoContainer}>
                            {printContactInfoInputs}
                        </div>
                        <div className={classes.ContentContainer}>
                            <Input
                                elementType={contentInput.elementType}
                                elementConfig={contentInput.elementConfig}
                                value={contentInput.value}
                                elementPlaceholder={contentInput.placeholder}
                                changed={(event) => this.inputChangedHandler(event, 'content')}
                                blurred={() => this.sanitizeInput('content')} 
                                currentLanguage={this.props.currentLanguage} 
                                err = {!contentInput.valid ? contentInput.err : null} />
                        </div>
                    </form>
                    {button}
                </div>
            </CloudBackground>
        );
    }
}

export default ContactData;