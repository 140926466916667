import React from 'react';

import cloudImg from '../../../../../resources/cloud-maliva.png';
// import cloud2img from '../../../../../resources/video-background-two.png';
// import cloud3img from '../../../../../resources/video-background-three.png';
// import cloud4img from '../../../../../resources/video-background-four.png';

import classes from './CloudBackground.module.css';

const cloudBackground = (props) => {
    return (
        <div className={classes.CloudBackground} id={props.id}>
            {props.children}

            <div className={classes.CloudBottomRight}><img className={classes.CloudImage} src={cloudImg} alt="cloud one" /></div>
            <div className={classes.CloudTopRight}><img className={classes.CloudImage} src={cloudImg} alt="cloud two" /></div>
            <div className={classes.CloudBottomLeft}><img className={classes.CloudImage} src={cloudImg} alt="cloud three" /></div>
            <div className={classes.CloudTopLeft}><img className={classes.CloudImage} src={cloudImg} alt="cloud four" /></div>
        </div>
    );
}

export default cloudBackground;