import React from 'react';
import { Translate } from "react-localize-redux";

import downArrowImg from '../../../../../resources/go-down-arrow.png';

import classes from './SeeMoreButton.module.css';

const seeMoreButton = (props) => {
    return (
        <div className={classes.SeeMoreButton}>
            <div className={classes.SeeMoreButtonContent} >
                <p><Translate id='homePage.seeMore' /></p>
                <h3><Translate id='homePage.aboutUs' /></h3>
                <img src={downArrowImg} className={classes.GoDownArrow} alt='go down arrow' />
            </div>
        </div>
    );
}

export default seeMoreButton;