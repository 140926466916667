import React from 'react';

import classes from './Tab.module.css';

const tab = (props) => {
    const activated = props.active ? (
        <div className={classes.Pointer}></div>
    ) : null;

    return (
        <div className={classes.Tab} onClick={props.clicked} >
            {props.children}
            {activated}
        </div>
    );
}

export default tab;