import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { Translate } from "react-localize-redux";

import AboutUsText from '../../../../containers/AboutUsText/AboutUsText';
import Button from '../../../UI/Button/Button';

import classes from './AboutUsSection.module.css';

const aboutUsSection = (props) => {
    return (
        <div className={classes.AboutUsSection}>
            <AboutUsText currentLanguage={props.currentLanguage}/>
            <div className={classes.ButtonArea}>
                <HashLink to='/about-us#video' scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'center' })}>
                    <Button color="#FF672E"><Translate id="aboutUsPage.seeVideo" /></Button>
                </HashLink>
                <HashLink to='/about-us#history' scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
                    <Button><Translate id="aboutUsPage.ourHistory" /></Button>
                </HashLink>
            </div>
        </div>
    );
}

export default aboutUsSection;