import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize } from "react-localize-redux";

import Layout from './hoc/Layout/Layout';
import HomePage from './components/Pages/HomePage/HomePage';
import AboutUsPage from './components/Pages/AboutUsPage/AboutUsPage';
import ContactUsPage from './components/Pages/ContactUsPage/ContactUsPage';
import Products from './containers/Products/Products';
import globalTranslations from './translations/global.json';

class App extends Component {
  constructor(props) {
    super(props);

    this.props.initialize({
      languages: [
        { name: "Bulgarian", code: "BG" },
        { name: "English", code: "EN" }
      ],
      translation: globalTranslations,
      options: { renderToStaticMarkup }
    });
  }

  state = {
    currentLanguage: 'BG'
  }

  changeCurrentLanguageHandler = (newLanguage) => {
    this.setState({currentLanguage: newLanguage});
  }

  render() {
    return (
      <div>
        <Layout changeLanguage = {this.changeCurrentLanguageHandler} >
          <Route path="/" exact render={() => (<HomePage currentLanguage={this.state.currentLanguage} />)}  />
          <Route path="/about-us" render={() => (<AboutUsPage currentLanguage={this.state.currentLanguage} />)} />
          <Route path="/contact-us" render={() => (<ContactUsPage currentLanguage={this.state.currentLanguage} />)}/>
          <Route path="/products" render={() => (<Products currentLanguage={this.state.currentLanguage} />)} />
        </Layout>
      </div>
    );
  }
}

export default withLocalize(App);
