import React from 'react';

import ContactData from '../../../containers/ContactData/ContactData';
import ContactUsSection from '../HomePage/ContactUsSection/ContactUsSection';
import ProductPortfolio from '../../../containers/ProductPortfolio/ProductPortfolio';

import classes from './ContactUsPage.module.css';

const contactUsPage = (props) => {
    return (
        <div className={classes.ContactUsPage} >
            <ContactData currentLanguage={props.currentLanguage} />
            <ContactUsSection title={{BG: 'Пишете ни в Messenger', EN: 'Write us in Messenger'}} currentLanguage={props.currentLanguage}/>
            <ProductPortfolio currentLanguage={props.currentLanguage} />
        </div>
    );
}

export default contactUsPage;