import React from 'react';

import { withRouter, Redirect } from 'react-router-dom';

import ProductNavBar from './ProductNavBar/ProductNavBar';
import ContactUsSection from '../../HomePage/ContactUsSection/ContactUsSection';
import SingleProductBanner from './SingleProductBanner/SingleProductBanner';
import classes from './SingleProductPage.module.css';

const singleProductPage = (props) => {
    const currentProduct = props.products.find(product => product.slug === props.match.params.slug);
    if(currentProduct.category !== props.activeCategory.id) props.changeActiveCategory(currentProduct.category);
    const currentProducts = props.products.filter(product => product.category === currentProduct.category);
    // console.log(currentProducts);
    const currentProductIndex = currentProducts.findIndex(product => product.slug === props.match.params.slug);
    const nextProductIndex = (currentProductIndex + 1) % currentProducts.length;
    const nextProductSlug = currentProducts[nextProductIndex].slug;
    const prevProductIndex = (currentProductIndex + currentProducts.length - 1) % currentProducts.length;
    const prevProductSlug = currentProducts[prevProductIndex].slug;
    console.log(currentProduct.category);

    const neighbours = {
        next: nextProductSlug,
        prev: prevProductSlug
    }

    if(currentProduct === undefined) {
        return <Redirect to='/products' />
    }

    return (
        <div>
            <ProductNavBar activeCategory={props.activeCategory} product={currentProduct} currentLanguage={props.currentLanguage}/>
            <SingleProductBanner 
                product={currentProduct} 
                index={currentProductIndex} 
                neighbours={neighbours} 
                allProducts={currentProducts.length} 
                currentLanguage={props.currentLanguage}
                activeCategory={props.activeCategory}/>
            <div className={classes.ContactUsContainer}>
                <ContactUsSection showMailBtn currentLanguage = {props.currentLanguage}/>
            </div>
        </div>
    );
}

export default withRouter(singleProductPage);