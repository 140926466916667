import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import windowSize from 'react-window-size';

import img from '../../../../../resources/alpine-four-pack.png'
import nextBtnImage from '../../../../../resources/next-product-arrow-home-page.png'
import CarouselItem from './CarouselItem/CarouselItem';

import classes from './ProductCarousel.module.css';

class ProductCarousel extends React.Component { 
    state = {
        currentPosition: 0,
        itemsInSlide: 3
    }

    responsive = {
        0: { items: 3 },
        779: { items: 4 },
        829: { items: 2 },
        1105: { items: 3 }
    };
 
  onSlideChanged = (e) => {
    console.log('Item`s position after changes: ', e.item);
    console.log('Slide`s position after changes: ', e.slide);
    this.setState({currentPosition: e.item});
  };

  onInitialized = (e) => {
      if(e.itemsInSlide !== this.state.itemsInSlide) {
          this.setState({itemsInSlide: e.itemsInSlide});
      }
  }

  onResized = (e) => {
    if(e.itemsInSlide !== this.state.itemsInSlide) {
        this.setState({itemsInSlide: e.itemsInSlide});
    }
  }
  
  galleryItems() {
    const dummyItem = <div className={classes.DummyItem} />;
    const items = this.props.products.map((item, i) => (
        <CarouselItem product={item} key={i} currentLanguage={this.props.currentLanguage} />
    ));

    if(this.props.windowWidth < 830) {
        items.push(dummyItem);
        items.unshift(dummyItem);
    }

    return (
        items
    )
    
  };
  
  render() {
    const items = this.galleryItems();
    // console.log(items);

    const leftButton = this.state.currentPosition !== 0 ? (
        <img 
            src={nextBtnImage} 
            alt="arrow" 
            className={classes.PrevButton} 
            onClick={() => this.Carousel._slidePrev()} />
    ) : null;

    const rightButton = this.state.currentPosition < this.props.products.length - this.state.itemsInSlide ? (
        <img 
            src={nextBtnImage} 
            alt="arrow" 
            className={classes.NextButton} 
            onClick={() => this.Carousel._slideNext()} />
    ) : null;
 
    return (
        <div className={classes.CarouselContainer}>

            <div className={classes.CarouselWrapper}>
                <AliceCarousel 
                    className={classes.Carousel}
                    items={items}
                    startIndex = {this.state.currentPosition}
                    fadeOutAnimation={true}
                    mouseDragEnabled={true}
                    responsive={this.responsive}
                    onSlideChange={this.onSlideChange}
                    onSlideChanged={this.onSlideChanged}
                    onInitialized={this.onInitialized}
                    onResized={this.onResized}
                    buttonsDisabled={true}
                    dotsDisabled={true}
                    ref={el => this.Carousel = el}
                    infinite={false}
                    keysControlDisabled={true}
                />
            </div>
            {leftButton}
            {rightButton}
            <div className={classes.OverlayLeft}></div>
            <div className={classes.OverlayRight}></div>
        </div>
      
    );
  }
}

export default windowSize(ProductCarousel);