import React from 'react';
import { Translate } from "react-localize-redux";

import NavigationItem from './NavigationItem/NavigationItem';

import classes from './NavigationItems.module.css';

const navigationItems = (props) => {
    const assignedClasses = [classes.NavigationItems];
    return (
        <ul className={assignedClasses.join(' ')}>
            {/* <NavigationItem link="/" exact>Burger Builder</NavigationItem>
            <NavigationItem link="/orders">Orders</NavigationItem> */}
            <NavigationItem link="/" exact clicked={props.clickNavLink}><Translate id="menu.home" /></NavigationItem>
            <NavigationItem link="/about-us" exact clicked={props.clickNavLink}><Translate id="menu.aboutUs" /></NavigationItem>
            <NavigationItem link="/products" clicked={props.clickNavLink}><Translate id="menu.products" /></NavigationItem>
            <NavigationItem link="/contact-us" exact clicked={props.clickNavLink}><Translate id="menu.contacts" /></NavigationItem>
        </ul>
    );
}

export default navigationItems;