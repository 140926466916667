import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import axios from '../../axiosConfig';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import Spinner from '../../components/UI/Spinner/Spinner';

import TabsBar from '../../components/Pages/HomePage/ProductSection/TabsBar/TabsBar';
import ProductBanner from '../../components/Pages/ProductsPage/ProductBanner/ProductBanner';
import SingleProductPage from '../../components/Pages/ProductsPage/SingleProductPage/SingleProductPage';

class Products extends Component {
    state = {
        categories: [
            {
                id: "5c0eada743c7c42ee5760a4b",
                slug: "toilet-paper",
                title: {
                    BG: "Тоалетна хартия",
                    EN: "Toilet paper"
                }
            },
            {
                id: "5c0eadcc43c7c42ee5760a4c",
                slug: "kitchen-paper",
                title: {
                    BG: "Кухненска хартия",
                    EN: "Kitchen paper"
                }
            },
            {
                id: "5c0eadf443c7c42ee5760a4d",
                slug: "napkins",
                title: {
                    BG: "Салфетки",
                    EN: "Napkins"
                }
            }
        ],
        products: [
            // {
            //     _id: 'adivnidfvnd',
            //     slug: 'maliva-atlantic',
            //     title: 'Малива Атлантик',
            //     image: atlanticImg,
            //     description: 'Да създадеш докосване, нежно като облак, не е лесно. Но Малива знае как!  ',
            //     color: {
            //         top: "#0E9CCA",
            //         bottom: "#ACFBFF",
            //     },
            //     easyOpen: true,
            //     sizes: [
            //         { 
            //             name: 'atlantic-four-pack', 
            //             label: '4 рула', 
            //             image: atlanticFourPackImg,
            //             description: 'Малива е висококачествена хартия, позната със своята неустоима и изключително нежна мекота'
            //         },
            //         { 
            //             name: 'atlantic-eight-pack', 
            //             label: '16 рула', 
            //             image: atlanticEightPackImg, 
            //             description: 'Малива е висококачествена хартия, позната със своята неустоима и изключително нежна мекота'
            //         },
            //     ]
            // },
            // {
            //     _id: 'fgsdfgsfbfv',
            //     slug: 'maliva-tropical',
            //     title: 'Малива Тропикал',
            //     image: tropicalImg,
            //     description: 'Новата малива е още по-мека, благодарение на новия модерен и прецизен релеф с Пиксел технология',
            //     color: {
            //         top: "#F15031",
            //         bottom: "#FFA72C",
            //     },
            //     sizes: [
            //         { 
            //             name: 'tropical-four-pack', 
            //             label: '4 рула', 
            //             image: tropicalFourPackImg, 
            //             description: 'Малива е висококачествена хартия, позната със своята неустоима и изключително нежна мекота'
            //         },
            //         { 
            //             name: 'tropical-eight-pack', 
            //             label: '16 рула', 
            //             image: tropicalEightPackImg, 
            //             description: 'Малива е висококачествена хартия, позната със своята неустоима и изключително нежна мекота'
            //         },
            //     ]
            // },
            // {
            //     _id: 'adivdtjgvedd',
            //     slug: 'maliva-alpin',
            //     title: 'Малива Алпин',
            //     image: alpineImg,
            //     description: 'Новата малива е още по-мека, благодарение на новия модерен и прецизен релеф с Пиксел технология',
            //     color: {
            //         top: "#046D40",
            //         bottom: "#D9E69A",
            //     },
            //     sizes: [
            //         { 
            //             name: 'alpine-four-pack', 
            //             label: '4 рула', 
            //             image: alpineFourPackImg,
            //             description: 'Малива е висококачествена хартия, позната със своята неустоима и изключително нежна мекота'
            //         },
            //         { 
            //             name: 'alpine-eight-pack', 
            //             label: '16 рула', 
            //             image: alpineEightPackImg,
            //             description: 'Малива е висококачествена хартия, позната със своята неустоима и изключително нежна мекота'
            //         },
            //         { 
            //             name: 'alpine-eight-pack-2', 
            //             label: '16 рула', 
            //             image: alpineEightPackImg,
            //             description: 'Малива е висококачествена хартия, позната със своята неустоима и изключително нежна мекота'
            //         },
            //     ]
            // }
        ],
        activeCategory: {
            id: "5c0eada743c7c42ee5760a4b",
            slug: "toilet-paper",
            title: {
                BG: "Тоалетна хартия",
                EN: "Toilet paper"
            }
        },
        loading: true
    }

    async componentDidMount () {
        const categoriesArray = await this.fetchCategories();
        const activeCategory = categoriesArray[0];
        const productsArray = await this.fetchProducts();
        if(categoriesArray.length > 0) this.setState({categories: categoriesArray, products: productsArray, activeCategory: activeCategory}, () => {
            this.stopLoading();
        });
    }

    async fetchCategories () {
        this.setState({loading: true});
        try {
            const categoriesResponse = await axios.get('api/categories');
            const data = categoriesResponse.data;
            const categoriesArray = [];
            for (const key in data) {
                const singleCategory = {id: data[key]._id, slug: data[key].slug, title: data[key].title};
                categoriesArray.push(singleCategory);
            }
            return categoriesArray;
        } catch {
            this.setState({loading: false});
            return [];
        }
        
    }

    async fetchProducts () {
        const productsResponse = await axios.get('api/products');
        
        const data = productsResponse.data ? productsResponse.data : {};
        const productsArray = [];
        for(const key in data) {
            productsArray.push({...data[key]});
        }
        return productsArray;
    }

    stopLoading = () => {
        this.setState({loading: false});
    }

    changeActiveCategoryHandler = (newCategory) => {
        this.setState({activeCategory: newCategory});
    }

    changeCategoryFromIdHandler = (newCategoryId) => {
        const newCategory = this.state.categories.find(category => category.id === newCategoryId);
        this.setState({activeCategory: newCategory});
    }
    
    render () {

        const currentProducts = this.state.products.filter(product => product.category === this.state.activeCategory.id);

        const productBanners = currentProducts.map((product, index) => (
            <ProductBanner key={index} product={product} index={index} easyOpen currentLanguage={this.props.currentLanguage}/>
        ))

        let content = this.state.products.length === 0 ? null : (
            <>
                <Route path="/products" exact render={() => (
                    <>
                        <TabsBar 
                            categories={this.state.categories} 
                            activeCategory={this.state.activeCategory} 
                            changeCategory={this.changeActiveCategoryHandler} 
                            currentLanguage={this.props.currentLanguage} 
                            loading={this.state.loading}
                            showContactUsLink />
                        {productBanners}
                        
                    </>
                )} />
                <Route path="/products/:slug" render={() => (
                    <SingleProductPage 
                        products={this.state.products}
                        activeCategory={this.state.activeCategory}
                        currentLanguage = {this.props.currentLanguage}
                        changeActiveCategory = {this.changeCategoryFromIdHandler}/>
                )} />
            </>
        );

        content = this.state.loading ? <Spinner /> : content;

        return content;
    }
}

export default withErrorHandler(Products, axios);