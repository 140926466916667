import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './hoc/ScrollToTop/ScrollTopTop';
import { LocalizeProvider } from "react-localize-redux";

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const app = (
    <LocalizeProvider>
        <BrowserRouter>
            <ScrollToTop>
                <App />
            </ScrollToTop>
        </BrowserRouter>
    </LocalizeProvider>
)

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
