import React, { Component }  from 'react';
import LanguageSelect from './LanguageSelect/LanguageSelect';
import { Link } from 'react-router-dom';

import NavigationItems from './NavigationItems/NavigationItems';
import Logo from '../../resources/cloud_logo.png';
import MenuIcon from './MobileMenu/MenuIcon/MenuIcon';
import MobileMenu from './MobileMenu/MobileMenu';

import classes from './Toolbar.module.css';

class Toolbar extends Component {
    state = {
        showMobileMenu: false
    }

    openMenuHandler = () => {
        this.setState({showMobileMenu: true});
    }

    closeMenuHandler = () => {
        this.setState({showMobileMenu: false});
    }

    render () {
        const mobileMenu = this.state.showMobileMenu ? <MobileMenu close={this.closeMenuHandler} show={this.state.showMobileMenu} clickNavLink={this.closeMenuHandler}/> : null;

        return (
            <header className={classes.Toolbar}>
                <div className={classes.Logo}>
                    <Link to='/' ><img src={Logo} alt="Maliva Logo" /></Link>
                </div>
                <nav className={classes.DesktopAndTablet}>
                    <NavigationItems/>
                </nav>
                <div className={classes.LanguageSelectContainer}>
                    <LanguageSelect showMenu={this.state.showMobileMenu} changeLanguage = {this.props.changeLanguage}/>
                </div>
                <div className={classes.MenuIconContainer + ' ' + classes.MobileOnly}>
                    <MenuIcon clicked={this.openMenuHandler}/>
                </div>
                {mobileMenu}

            </header>
        );
    }
}

export default Toolbar;