import React from 'react';
import classes from './AlpinSnowfall.module.css';

const alpinSnowfall = (props) => {
    return (
        <div className={classes.SnowfallBackground}>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
            <i className={classes.Snowflake}></i>
        </div>
    );
}

export default alpinSnowfall;