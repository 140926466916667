import React, { Component } from 'react';
import Portfolio from '../../components/Pages/AboutUsPage/Portfolio/Portfolio';
import axios from '../../axiosConfig';

// import atlanticImg from '../../resources/product-list-mignon-atlantic.png';
// import tropicalImg from '../../resources/product-list-mignon-tropical.png';
// import alpineImg from '../../resources/product-list-mignon-alpine.png';
import Spinner from '../../components/UI/Spinner/Spinner';

class ProductPortfolio extends Component {
    state = {
        products: [
            {
                slug: "maliva-atlantic",
                category: "5c0eada743c7c42ee5760a4b",
                image: {
                    secure_url: "https://res.cloudinary.com/keystone-demo/image/upload/v1544472513/rsj0rivjxjn47f6mrf14.png",
                    url: "http://res.cloudinary.com/keystone-demo/image/upload/v1544472513/rsj0rivjxjn47f6mrf14.png",
                },
                color: {
                    bottom: "#ACFBFF",
                    top: "#0E9CCA"
                },
                description: {
                    BG: "Да създадеш докосване, нежно като облак, не е лесно. Но Малива знае как! ",
                    EN: "Some description of Maliva Atlantic in English"
                },
                title: {
                    BG: "Малива Aтлантик",
                    EN: "Maliva Atlantic"
                }
            },
            {
                slug: "maliva-tropical",
                category: "5c0eada743c7c42ee5760a4b",
                image: {
                    secure_url: "https://res.cloudinary.com/keystone-demo/image/upload/v1544472771/umomjn3wgpor9d8lq3hq.png",
                    url: "http://res.cloudinary.com/keystone-demo/image/upload/v1544472771/umomjn3wgpor9d8lq3hq.png",
                },
                color: {
                    bottom: "#FFA72C",
                    top: "#F15031"
                },
                description: {
                    BG: "Новата малива е още по-мека, благодарение на новия модерен и прецизен релеф с Пиксел технология",
                    EN: "Some description of Maliva Tropical Toilet Paper in english"
                },
                title: {
                    BG: "Малива Тропикал",
                    EN: "Maliva Tropical"
                }
            },
            {
                slug: "maliva-alpin",
                category: "5c0eada743c7c42ee5760a4b",
                image: {
                    secure_url: "https://res.cloudinary.com/keystone-demo/image/upload/v1544472965/kwli3jffyyiz0avp6mlm.png",
                    url: "http://res.cloudinary.com/keystone-demo/image/upload/v1544472965/kwli3jffyyiz0avp6mlm.png",
                },
                color: {
                    bottom: "#D9E69A",
                    top: "#046D40"
                },
                description: {
                    BG: "Новата малива е още по-мека, благодарение на новия модерен и прецизен релеф с Пиксел технология",
                    EN: "Some description of Maliva Alpine in english"
                },
                title: {
                    BG: "Малива Алпин",
                    EN: "Maliva Alpine"
                }
            },
            // {
            //     slug: "kitchen-paper",
            //     category: "5c0eadcc43c7c42ee5760a4c",
            //     image: {
            //         "secure_url": "https://res.cloudinary.com/keystone-demo/image/upload/v1544626378/cz1i4izalcdeta0kjkbo.jpg",
            //         "url": "http://res.cloudinary.com/keystone-demo/image/upload/v1544626378/cz1i4izalcdeta0kjkbo.jpg",
            //     },
            //     color: {
            //         bottom: "#e0e0e0",
            //         top: "#ff0000"
            //     },
            //     description: {
            //         BG: "жнйфдскжнксфжкй",
            //         EN: "vnedjvnjdvjnfd"
            //     },
            //     title: {
            //         BG: "Кухненска хартия",
            //         EN: "Kitchen Paper"
            //     }
            // }
        ],
        loading: true
    }

    async componentDidMount () {
        // const categoriesArray = await this.fetchCategories();
        // const activeCategory = categoriesArray[0];
        // const productsArray = await this.fetchProducts();
        // if(categoriesArray.length > 0) this.setState({categories: categoriesArray, products: productsArray, activeCategory: activeCategory}, () => {
        //     this.stopLoading();
        // });

        const productsArray = await this.fetchProducts();
        if(productsArray.length > 0) this.setState({products: productsArray}, () => {this.stopLoading()});
    }

    async fetchCategories () {
        this.setState({loading: true});
        try {
            const categoriesResponse = await axios.get('api/categories');
            const data = categoriesResponse.data;
            const categoriesArray = [];
            for (const key in data) {
                const singleCategory = {id: data[key]._id, slug: data[key].slug, title: data[key].title};
                categoriesArray.push(singleCategory);
            }
            return categoriesArray;
        } catch {
            this.setState({loading: false});
            return [];
        }
        
    }

    async fetchProducts () {
        this.setState({loading: true});
        try {
            const productsResponse = await axios.get('api/products');
            
            const data = productsResponse.data;
            const productsArray = [];
            for(const key in data) {
                productsArray.push({...data[key]});
            }
            return productsArray;
        } catch {
            this.setState({loading: false});
            return [];
        }
    }

    stopLoading = () => {
        this.setState({loading: false});
    }
    
    render () {
        const portfolio = this.state.loading ? <Spinner /> : <Portfolio products={this.state.products} currentLanguage={this.props.currentLanguage}/>

        return portfolio;
    }
}

export default ProductPortfolio;