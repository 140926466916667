import React from 'react';
import { Link } from 'react-router-dom';
import { Translate } from "react-localize-redux";

import Button from '../../../UI/Button/Button'; 
import ContactUsText from '../../../../containers/ContactUsText/ContactUsText';
import mignonsImage from '../../../../resources/mignons-contact-home-page.png';
import messengerIcon from '../../../../resources/messanger-icon-transparent.png';
import mailIcon from '../../../../resources/send-email-icon.png';

import classes from './ContactUsSection.module.css';

const contactUsSection = (props) => {
    const mailButton = props.showMailBtn ? (
        <Link to="/contact-us" >
            <Button customStyle={{width: '260px'}}><img src={mailIcon} alt="icon" style={{marginRight: "13px"}} /><Translate id='homePage.email' /></Button>
        </Link>
    ) : null;

    const messengerBtnContent = props.showMailBtn ? <Translate id='homePage.messenger' /> : <Translate id='homePage.sendMessenger' />;

    return (
        <>
            <div className={classes.ContactUs}>
                <div className={classes.ImageBox + ' ' + classes.DesktopOnly}>
                    <img src={mignonsImage} alt="mignons" />
                </div>
                <div className={classes.ContentBox}>
                    {/* <h2>
                        {props.title}
                    </h2>
                    <p>Малива е висококачествена хартия позната със своята неустоима мекота. Разумната й цена и веселата й,</p> */}
                    <ContactUsText currentLanguage={props.currentLanguage} title={props.title}/>
                    <br />
                    <div className={classes.ButtonsBox}>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/MalivaEU/"><Button><img src={messengerIcon} alt="icon" style={{marginRight: "13px"}} />{messengerBtnContent}</Button></a>
                        {mailButton}
                    </div>
                </div>
                <div className={classes.ImageBox + ' ' + classes.MobileOnly}>
                    <img src={mignonsImage} alt="mignons" />
                </div>
            </div>
        </>
    );
}

export default contactUsSection;