import React, { Component } from 'react';
import axios from '../../axiosConfig';

import classes from './AboutUsText.module.css';

class AboutUsText extends Component {
    state = {
        title: {
            BG: "Кои сме ние?",
            EN: "Who are we?"
        },
        content: {
            BG: "<p>Да създадеш докосване, нежно като облак не е лесно. Но малива знае как!</p>\r\n<p>Новата Малива е още по-мека, благодарение на новия модерен и прецизен релеф с Пиксел технология. С три свежи аромата - тропикал, атлантик и алпин</p>",
            EN: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque sollicitudin turpis dolor, at placerat felis accumsan eu. Curabitur volutpat venenatis imperdiet.</p>\r\n<p>Pellentesque commodo fringilla mattis. Integer vehicula sollicitudin quam. Ut diam nulla, tristique ac vestibulum et, mattis a ante. </p>"
        },
        loading: true
    }

    async componentDidMount () {
        // const storiesArray = await this.fetchHistory();
        // if(storiesArray.length > 0) this.setState({stories: storiesArray}, () => {this.stopLoading()});
        const aboutUsText = await this.fetchAboutUsText();
        if(aboutUsText) this.setState({title: aboutUsText.title, content: aboutUsText.content}, () => {this.stopLoading()});
    }

    async fetchAboutUsText () {
        this.setState({loading: true});
        try {
            const aboutUsResponse = await axios.get('api/content/about-us');
            const aboutUsText = aboutUsResponse.data.data;
            return aboutUsText;
        } catch {
            this.setState({loading: false});
            return null;
        }
    }

    // async testFetching () {
    //     const aboutUsResponse = await axios.get('api/content/about-us');
    //     const data = aboutUsResponse.data.data;
    //     console.log(data);
    // }

    stopLoading = () => {
        this.setState({loading: false});
    }

    createMarkup = () => {
        const content = this.state.content[this.props.currentLanguage]
        return {__html: content}
    }

    render () {
        const title = this.state.title[this.props.currentLanguage];

        return (
            <div className={classes.AboutUsText}>            
                <h2>{title}</h2>
                <div dangerouslySetInnerHTML={this.createMarkup()} />
            </div>
            
        );
    }
}

export default AboutUsText;