import React from 'react';
import { Translate } from "react-localize-redux";
// import videoThumbnailBackground from '../../../../../resources/video-screenshot.png';
import playButtonIcon from '../../../../../resources/play-button-icon.png';

import classes from './VideoThumbnail.module.css';

const videoThumbnail = (props) => {
    const thumbnailBG = props.thumbnailBG;

    const heading = props.videoId === 'biR5-HpCC4I' ? <h2><span className={classes.NewLine}><Translate id='homePage.titleFirst' /></span><Translate id='homePage.titleSecond' /></h2> : <h2> </h2>;

    return (
        <div className={classes.VideoThumbnail} >
            <img className={classes.Background} src={thumbnailBG} alt='play button icon' />
            <div className={classes.ThumbnailContent}>
                {heading}
                <div className={classes.PlayVideoContainer} onClick={props.clickPlay} >
                    <p><Translate id='homePage.play' /></p>
                    <img 
                        className={classes.PlayButton} 
                        src={playButtonIcon} 
                        alt='play button icon' />
                    <p><Translate id='homePage.video' /></p>
                </div>
                
            </div>
        </div>
    );
}

export default videoThumbnail;