import React, { Component } from 'react';
import axios from '../../axiosConfig';

import TabsBar from '../../components/Pages/HomePage/ProductSection/TabsBar/TabsBar';
import ProductSection from '../../components/Pages/HomePage/ProductSection/ProductSection';

class HomeProducts extends Component {

    state = {
        categories: [
            {
                id: "5c0eada743c7c42ee5760a4b",
                slug: "toilet-paper",
                title: {
                    BG: "Тоалетна хартия",
                    EN: "Toilet paper"
                }
            },
            {
                id: "5c0eadcc43c7c42ee5760a4c",
                slug: "kitchen-paper",
                title: {
                    BG: "Кухненска хартия",
                    EN: "Kitchen paper"
                }
            },
            {
                id: "5c0eadf443c7c42ee5760a4d",
                slug: "napkins",
                title: {
                    BG: "Салфетки",
                    EN: "Napkins"
                }
            }
        ],
        products: [
            {
                slug: "maliva-atlantic",
                category: "5c0eada743c7c42ee5760a4b",
                sizes: [
                    {
                        image: {
                            secure_url: "https://res.cloudinary.com/keystone-demo/image/upload/v1544473093/zyrsgvxyffwht8fzpoxn.png"
                        }
                    }
                ],
                color: {
                    top: "#0E9CCA"
                },
                description: {
                    BG: "Новата малива е още по-мека, благодарение на новия модерен и прецизен релеф с Пиксел технология",
                    EN: "Some description of Maliva Atlantic in English"
                },
                title: {
                    BG: "Малива Aтлантик",
                    EN: "Maliva Atlantic"
                }
            },
            {
                slug: "maliva-tropical",
                category: "5c0eada743c7c42ee5760a4b",
                sizes: [
                    {
                        image: {
                            secure_url: "https://res.cloudinary.com/keystone-demo/image/upload/v1544473218/v5eef6ddvynlzicyzfwx.png",
                        }
                    },
                ],
                color: {
                    top: "#F15031"
                },
                description: {
                    BG: "Новата малива е още по-мека, благодарение на новия модерен и прецизен релеф с Пиксел технология",
                    EN: "Some description of Maliva Tropical Toilet Paper in english"
                },
                title: {
                    BG: "Малива Тропикал",
                    EN: "Maliva Tropical"
                }
            },
            {
                slug: "maliva-alpin",
                category: "5c0eada743c7c42ee5760a4b",
                sizes: [
                    {
                        image: {
                            secure_url: "https://res.cloudinary.com/keystone-demo/image/upload/v1544627611/arhspnfszurtg4cxekxn.png",
                        }
                    },
                ],
                color: {
                    top: "#046D40"
                },
                description: {
                    BG: "Новата малива е още по-мека, благодарение на новия модерен и прецизен релеф с Пиксел технология",
                    EN: "Some description of Maliva Alpine in english"
                },
                title: {
                    BG: "Малива Алпин",
                    EN: "Maliva Alpine"
                }
            }
        ],
        activeCategory: {
            id: "5c0eada743c7c42ee5760a4b",
            slug: "toilet-paper",
            title: {
                BG: "Тоалетна хартия",
                EN: "Toilet paper"
            }
        },
        loading: true
    }

    async componentDidMount () {
        const categoriesArray = await this.fetchCategories();
        const activeCategory = categoriesArray[0];
        const productsArray = await this.fetchProducts();
        if(categoriesArray.length > 0) this.setState({categories: categoriesArray, products: productsArray, activeCategory: activeCategory, loading: false});
    }

    async fetchCategories () {
        this.setState({loading: true});
        try {
            const categoriesResponse = await axios.get('api/categories');
            const data = categoriesResponse.data;
            const categoriesArray = [];
            for (const key in data) {
                const singleCategory = {id: data[key]._id, slug: data[key].slug, title: data[key].title};
                categoriesArray.push(singleCategory);
            }
            return categoriesArray;
        } catch {
            this.setState({loading: false});
            return [];
        }
        
    }

    async fetchProducts () {
        const productsResponse = await axios.get('api/products');
        
        const data = productsResponse.data;
        const productsArray = [];
        for(const key in data) {
            productsArray.push({...data[key]});
        }
        return productsArray;
    }

    changeActiveCategoryHandler = (newCategory) => {
        this.setState({activeCategory: newCategory});
    }

    render () {
        const currentProducts = this.state.products.filter(product => product.category === this.state.activeCategory.id);
        return (
            <div style={{backgroundColor: '#f3f3f3', position: 'relative', zIndex: '1'}}>
                <TabsBar 
                    categories={this.state.categories} 
                    activeCategory={this.state.activeCategory} 
                    changeCategory={this.changeActiveCategoryHandler} 
                    currentLanguage={this.props.currentLanguage} 
                    loading={this.state.loading}
                />
                <ProductSection 
                    products={currentProducts} 
                    currentLanguage={this.props.currentLanguage} 
                    loading={this.state.loading}
                />
            </div>
        );
    }
}

export default HomeProducts;