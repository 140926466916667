import React from 'react';

import classes from './MenuIcon.module.css';

const menuIcon = (props) => {
    return (
        <div className={classes.MenuIcon} onClick={props.clicked}>
            <div className={classes.Bar1}></div>
            <div className={classes.Bar2}></div>
            <div className={classes.Bar3}></div>
        </div>
        
    );
}

export default menuIcon;