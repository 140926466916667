import React, { Component } from 'react';
import { withLocalize } from "react-localize-redux";

import classes from './LanguageSelect.module.css';

class LanguageSelect extends Component {

    state = {
        showDropdown: false,
        languages: [
            { id: 'BG', label: 'Български', flagClass: "flag-icon flag-icon-bg" },
            { id: 'EN', label: 'English', flagClass: "flag-icon flag-icon-gb" }
        ],
        selectedLanguage: { id: 'BG', label: 'Български', flagClass: "flag-icon flag-icon-bg" }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (this.props.showMenu !== nextProps.showMenu || this.state.showDropdown !== nextState.showDropdown);
    }

    componentWillUpdate(nextProps) {
        if (nextProps.showMenu === true) this.setState({ showDropdown: false });
    }

    toggleDropdownHandler = () => {
        const isShown = this.state.showDropdown;
        this.setState({ showDropdown: !isShown });
    }

    changeLanguageHandler = (language) => {
        const newLanguage = { ...language };
        this.setState({ selectedLanguage: newLanguage });
        this.toggleDropdownHandler();
        this.props.changeLanguage(language.id);
        this.props.setActiveLanguage(language.id);
    }

    render() {
        const populateDropdown = this.state.languages.map(language => (
            <div key={language.id} className={classes.Option} onClick={() => this.changeLanguageHandler(language)}>
                <span className={[language.flagClass, classes.FlagContainer].join(' ')} ></span>
                <div>{language.label}</div>
            </div>
        ));

        const dropDown = this.state.showDropdown ? (
            <div className={classes.CountryList}>
                {populateDropdown}
            </div>
        ) : null;

        const currentLanguage = (
            <div className={classes.Option + ' ' + classes.Selected}>
                <span className={[this.state.selectedLanguage.flagClass, classes.FlagContainer].join(' ')} ></span>
                {/* <div className={classes.FlagContainer} /> */}
                <div className={classes.LanguageLabel + ' ' + classes.DesktopAndTablet}>{this.state.selectedLanguage.label}</div>
            </div>
        );

        return (
            <div className={classes.LanguageSelect}>
                <div className={classes.SelectedContainer} onClick={this.toggleDropdownHandler}>
                    {currentLanguage}
                    <div className={classes.ArrowDown + ' ' + classes.DesktopAndTablet}>
                        ▾
                    </div>
                </div>

                {dropDown}
            </div>
        );
    }
}

export default withLocalize(LanguageSelect);