import React, { Component } from 'react';
import Aux from '../Aux/Aux';
import Footer from '../../components/Footer/Footer';
import Toolbar from '../../components/Toolbar/Toolbar';

import classes from './Layout.module.css';

class Layout extends Component {

    render () {
        return (
            <Aux>
                <Toolbar changeLanguage = {this.props.changeLanguage} />
                <main className={classes.Content}>
                    {this.props.children}
                </main>
                <Footer />
            </Aux>
        );
    }
}

export default Layout;