import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Translate } from "react-localize-redux";

import Button from '../../../../UI/Button/Button';
import easyOpenImage from '../../../../../resources/easy-open.png';
import waveImage from '../../../../../resources/atlantic-wave.svg';

import classes from './AtlanticBanner.module.css';


const atlanticBanner = (props) => {
    const product = props.product;
    const currentLanguage = props.currentLanguage;
    const title = product.title[currentLanguage] ? product.title[currentLanguage] : null;
    const description = product.description[currentLanguage] ? product.description[currentLanguage] : null;
    const image = product.image.secure_url;
    const colors = {
        top: product.color.top ? product.color.top : '#fff',
        bottom: product.color.bottom ? product.color.bottom : '#fff'
    }

    const contentClasses = [classes.Content];
    if((+props.index % 2) === 0) contentClasses.push(classes.LeftImage);

    const easyOpen = props.easyOpen ? (
        <div className={classes.EasyOpenContainer}>
            <img src={easyOpenImage} alt='easy open' />
        </div>
    ) : null;

    return (
        <div 
            className={classes.ProductBanner} 
            style={{ background: 'linear-gradient(to top, rgba(172,251,255,1) 0%, #1FB4E4 60%, #11A1CF 100%)' }}
            >
            <div className={classes.Ocean}>
                <div className={classes.Wave} style={{background: 'url(' + waveImage + ') repeat-x'}}></div>
                <div className={classes.Wave} style={{background: 'url(' + waveImage + ') repeat-x'}}></div>
            </div>
            <div className={contentClasses.join(' ')} >
                <div className={classes.ImageContainer}>
                    <img src={image} alt=".product" />
                </div>
                <div className={classes.BannerText}>
                    <Link to={props.match.url + '/' + product.slug} >
                        <h2>{title}</h2>
                        <p>{description}</p>
                        <Button color={colors.top} customStyle={{ marginLeft: '0px', marginRight: '0px' }}><Translate id="productsPage.readMore"/></Button>
                    </Link>
                </div>
            </div>
            {easyOpen}
        </div>
    );
}

export default withRouter(atlanticBanner);