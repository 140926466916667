import React, { Component } from 'react';
import axios from '../../axiosConfig';

import classes from './ContactUsText.module.css';

class ContactUsText extends Component {
    state = {
        title: {
            BG: "Свържете се с нас!",
            EN: "Connect with us!"
        },
        content: {
            BG: "<p>Малива е висококачествена хартия позната със своята неустоима мекота. Разумната й цена и веселата й,</p>",
            EN: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>\r\n<p>Ut diam nulla, tristique ac vestibulum et, mattis a ante. </p>"
        },
        loading: true
    }

    async componentDidMount () {
        const contactUsText = await this.fetchContactUsText();
        if(contactUsText) this.setState({title: contactUsText.title, content: contactUsText.content}, () => {this.stopLoading()});
    }

    async fetchContactUsText () {
        this.setState({loading: true});
        try {
            const contactUsResponse = await axios.get('api/content/contact-us');
            const contactUsText = contactUsResponse.data.data;
            return contactUsText;
        } catch {
            this.setState({loading: false});
            return null;
        }
    }

    stopLoading = () => {
        this.setState({loading: false});
    }

    createMarkup = () => {
        const content = this.state.content[this.props.currentLanguage]
        return {__html: content}
    }

    render () {
        const lang = this.props.currentLanguage;
        const title = this.props.title ? this.props.title[lang] : this.state.title[lang];

        return (
            <div className={classes.ContactUsText}>            
                <h2>{title}</h2>
                <div dangerouslySetInnerHTML={this.createMarkup()} />
            </div>
            
        );
    }
}

export default ContactUsText;