import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../../resources/cloud_logo.png';
// import Logo from '../../../resources/logo-navigation.png';
import NavigationItems from '../NavigationItems/NavigationItems';

import classes from './MobileMenu.module.css';


const mobileMenu = (props) => {
    // let navClasses = [classes.Overlay, classes.Closed];

    // if(props.show) navClasses = [classes.Overlay, classes.Open];

    return (
        <div className={classes.Overlay} >
            <div className={classes.CloseBtnContainer} onClick={props.close}>&times;</div>
            <div className={classes.Logo} onClick={props.clickNavLink}>
                <Link to='/' ><img src={Logo} alt="Maliva Logo" /></Link>
            </div>
            
            <div className={classes.OverlayContent}>
                <NavigationItems clickNavLink={props.clickNavLink}/>
            </div>
        </div>
    );
}

export default mobileMenu;