import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Translate } from 'react-localize-redux';

import grayMessengerIcon from '../../../../../resources/messanger-icon-gray.png';
import backArrow from '../../../../../resources/back-arrow-second-navigation.png'

import classes from './ProductNavBar.module.css';

const productNavBar = (props) => {
    const breadCrumbDivider = <span className={classes.BreadCrumbDivider}>  /  </span>;

    const currentLanguage = props.currentLanguage;
    const categoryTitle = props.activeCategory.title[currentLanguage];
    // console.log(currentLanguage);
    const product = props.product;
    // console.log(product);
    const productTitle = product.title[currentLanguage] ? product.title[currentLanguage] : "Maliva Atl";
    const splittedTitle = productTitle.split(' ');
    const onlyName = splittedTitle.length > 0 ? splittedTitle[splittedTitle.length - 1] : '';

    const currentPageBreadCrumb = (
        <div className={classes.CurrentPageBreadCrumb}>
            <Link to={props.match.url}>{categoryTitle} "{onlyName}"</Link>
        </div>
        )

    return (
        <div className={classes.ProductNavBar} >
            <Link to='/products'>
                <div className={classes.BackLink} >
                    <img src={backArrow} alt="back arrow" />
                    <Translate id="productsPage.allProducts" />
                </div>
            </Link>
            <div className={classes.BreadCrumbs} >
                <Link to='/'><Translate id="productsPage.home" /></Link>
                {breadCrumbDivider}
                <Link to='/products'><Translate id="productsPage.products" /></Link>
                {breadCrumbDivider}
                {currentPageBreadCrumb}
            </div>
            <div className={classes.ContactUsLink}>
                <Translate id="productsPage.connect" /> 
                <img src={grayMessengerIcon} alt='messenger icon' />
            </div>
        </div>
    );
}

export default withRouter(productNavBar);