import React from 'react';
import classes from './SizeModule.module.css';

const sizeModule = (props) => {

    const currentLanguage = props.currentLanguage;
    const sizeTitle = props.size.title[currentLanguage];
    const sizeDescription = props.size.description[currentLanguage];
    const image = props.size.image.secure_url;
    const heading = sizeTitle;

    return (
        <div className={classes.SizeModule} style={{borderBottom: "4px solid " + props.color}}>
            <div className={classes.ImageContainer} >
                <img src={image} alt={sizeTitle}/>
            </div>
            <h3 style={{color: props.color}}>{heading}</h3>
            <p>{sizeDescription}</p>
        </div>
    );
}

export default sizeModule;