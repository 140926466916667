import React from 'react';
import { Link } from 'react-router-dom';
import { Translate } from "react-localize-redux";

import Button from '../../../../UI/Button/Button';

import classes from './ProductSlide.module.css';

const productSlide = (props) => {
    const product = props.product;

    const slug = product.slug;
    const title = product.title[props.currentLanguage];
    const description = product.description[props.currentLanguage];
    const image = product.image.secure_url;
    const color = product.color.top;

    return (
        <div className={classes.Slide} >
            <div className={classes.ImageContainer}>
                <img src={image} alt="product" />
            </div>
            <div className={classes.SlideText}>
                <Link to={'/products/' + slug} >
                    <h3>{title}</h3>
                    <p>{description}</p>
                    <Button color={color} customStyle={{ marginLeft: '0px', marginRight: '0px' }}><Translate id="aboutUsPage.viewProduct" /></Button>
                </Link>
            </div>
        </div>
    );
}

export default productSlide;