import React from 'react';

import AboutUsSection from './AboutUsSection/AboutUsSection';
import VideoSection from '../HomePage/VideoSection/VideoSection';
import ProductPortfolio from '../../../containers/ProductPortfolio/ProductPortfolio';
import ContactUsSection from '../HomePage/ContactUsSection/ContactUsSection';
import History from '../../../containers/History/History';

import classes from './AboutUsPage.module.css';

const aboutUsPage = (props) => {
    return (
        <div className={classes.HomePage}>
            <AboutUsSection currentLanguage={props.currentLanguage}/>
            <VideoSection id='video'/>
            <History currentLanguage={props.currentLanguage}/>
            <ProductPortfolio currentLanguage={props.currentLanguage}/>
            <ContactUsSection showMailBtn currentLanguage={props.currentLanguage}/>
        </div>
    );
}

export default aboutUsPage;