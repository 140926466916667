import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Translate } from "react-localize-redux";

import classes from './Footer.module.css';
import footerLogo from '../../resources/logo-footer.png';
import arrowFooter from '../../resources/arrow-footer.png';

const footer = (props) => {
    return (
        <div className={classes.Footer}>
            <div className={classes.LogoContainer} ><img src={footerLogo} alt="footer logo" /></div>
            <div className={classes.LinksContainer} >
                <a href="/"><Translate id="footer.terms" /></a>
                <Link to="/contact-us"><Translate id="footer.contacts" /></Link>
            </div>
            <div className={classes.TrademarkContainer}>@ 2018 <Translate id="footer.rights" /></div>

            <div
                className={classes.BackToTop}
                onClick={() => window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                })}>
                <img src={arrowFooter} alt="back to top" />
            </div>
            <div className={classes.SoftZenLink}><Translate id="footer.developed" /> <a target="_blank" rel="noopener noreferrer" href='https://softzen.co'>SoftZen</a></div>
        </div>
    );
}

export default withRouter(footer);