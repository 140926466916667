import React from 'react';

import VideoSection from './VideoSection/VideoSection';
import AboutUsSection from './AboutUsSection/AboutUsSection';
import ContactUsSection from './ContactUsSection/ContactUsSection';
import HomeProducts from '../../../containers/HomeProducts/HomeProducts';

import classes from './HomePage.module.css';

const homePage = (props) => {
    return (
        <div className={classes.HomePage}>
            <VideoSection seeMoreBtn='/#about-us'/>
            <AboutUsSection id='about-us' currentLanguage={props.currentLanguage}/>
            <HomeProducts currentLanguage={props.currentLanguage}/>
            <ContactUsSection showMailBtn currentLanguage={props.currentLanguage}/>
        </div>
    );
}

export default homePage;