import React from 'react';
import { Translate } from 'react-localize-redux';


import classes from './Input.module.css';

const input = (props) => {
    const lang = props.currentLanguage;
    let inputElement = null;
    const inputClasses = [classes.InputElement];
    if(props.err) inputClasses.push(classes.InputErr);

    switch (props.elementType) {
        case ('input'):
            inputElement = <input 
                className={inputClasses.join(' ')} 
                {...props.elementConfig} 
                value={props.value}
                onChange={props.changed}
                onBlur={props.blurred}
                placeholder={props.elementPlaceholder[lang]} />;
            break;
        case ('textarea'):
            inputElement = <textarea 
                className={inputClasses.join(' ')} 
                {...props.elementConfig} 
                value={props.value}
                onChange={props.changed}
                onBlur={props.blurred}
                placeholder={props.elementPlaceholder[lang]} />;
            break;
        default:
            inputElement = <input 
                className={inputClasses.join(' ')} 
                {...props.elementConfig} 
                value={props.value}
                onChange={props.changed}
                onBlur={props.blurred} 
                placeholder={props.elementPlaceholder[lang]} />;
    }

    const errMsg = props.err ? <p className={classes.ErrMsg}><Translate id={'contactUsPage.'+ props.err} /></p> : null;

    return (
        <div className={classes.Input}>
            {inputElement}
            {errMsg}
        </div>
    );
}

export default input;