import React from 'react';
import { Link } from 'react-router-dom';
import Plx from 'react-plx';
import { Translate } from "react-localize-redux";

import AboutUsText from '../../../../containers/AboutUsText/AboutUsText';
import Button from '../../../UI/Button/Button';
import easyOpenImage from '../../../../resources/easy-open.png';
import mignonsImage from '../../../../resources/mignons-contact-home-page.png'

import classes from './AboutUsSection.module.css';

/**
 * TO DO: Code Cleanup, clean unused packages, return this back to functional component!!!
 */

const aboutUsSection = (props) => {


    const parallaxData = [
        {
            start: 'self',
            duration: '300px',
            properties: [
                {
                startValue: 100,
                endValue: 0,
                property: 'translateY',
                unit: '%'
                },
            ],
        },
    ];

    return (
        <div className={classes.AboutUsSection} id={props.id}>
            <div className={classes.EasyOpenContainer}>
                <img src={easyOpenImage} alt='easy open' />
            </div>
            <AboutUsText currentLanguage={props.currentLanguage}/>
            <div className={classes.ButtonArea}>
                <div className={classes.AboutUsButton}>
                    <Link to='/about-us'>
                        <Button color="#41B3E4"><Translate id='homePage.moreAboutUs' /></Button>
                    </Link>
                </div>

                <Plx className={classes.ImageContainer} parallaxData={parallaxData}>
                        <img src={mignonsImage} alt="mignons" />
                </Plx>
                
                <div className={classes.MalivaHeroesButton}>
                    <Link to='/products'>
                        <Button color="#FF672E"><Translate id='homePage.malivaHeroes' /></Button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default aboutUsSection;