import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Translate } from "react-localize-redux";

import AtlanticBanner from './AtlanticBanner/AtlanticBanner';
import TropicalButterfly from './TropicalButterfly/TropicalButterfly';
import AlpinSnowfall from './AlpinSnowfall/AlpinSnowfall';
import Button from '../../../UI/Button/Button';
import easyOpenImage from '../../../../resources/easy-open.png';    

import classes from './ProductBanner.module.css';

const productBanner = (props) => {
    const product = props.product;
    const currentLanguage = props.currentLanguage;
    const colors = {
        top: product.color.top ? product.color.top : '#fff',
        bottom: product.color.bottom ? product.color.bottom : '#fff'
    }
    const title = product.title[currentLanguage] ? product.title[currentLanguage] : null;
    const description = product.description[currentLanguage] ? product.description[currentLanguage] : null;
    const image = product.image.secure_url;
    const easyOpen = product.easyOpen;

    if(product.slug === 'maliva-atlantic') {
        return <AtlanticBanner product={product} index={props.index} currentLanguage={currentLanguage} easyOpen/>
    }

    const contentClasses = [classes.Content];
    if((+props.index % 2) === 0) contentClasses.push(classes.LeftImage);

    const easyOpenTag = easyOpen ? (
        <div className={classes.EasyOpenContainer}>
            <img src={easyOpenImage} alt='easy open' />
        </div>
    ) : null;

    const tropicalButterfly = product.slug === 'maliva-tropical' ? (
        <div className={classes.TropicalButterflyContainer}>
            <TropicalButterfly />
        </div>
    ) : null;

    const alpinSnowfall = product.slug === 'maliva-alpin' ? (
        <AlpinSnowfall />
    ) : null;

    return (
        <div 
            className={classes.ProductBanner} 
            style={{ backgroundImage: 'linear-gradient(to bottom,' + colors.top + ',' + colors.bottom + ')' }}
            >
            {alpinSnowfall}
            <div className={contentClasses.join(' ')} >
                <div className={classes.ImageContainer}>
                    <img src={image} alt="product" />
                    {tropicalButterfly}
                </div>
                
                <div className={classes.BannerText}>
                    <Link to={props.match.url + '/' + product.slug} >
                        <h2>{title}</h2>
                        <p>{description}</p>
                        <Button color={colors.top} customStyle={{ marginLeft: '0px', marginRight: '0px' }}><Translate id="productsPage.readMore"/></Button>
                    </Link>
                </div>
                
            </div>
            {easyOpenTag}
        </div>
    );
}

export default withRouter(productBanner);