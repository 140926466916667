import React from 'react';
import Spinner from '../../../UI/Spinner/Spinner';

import ProductCarousel from './ProductCarousel/ProductCarousel';
import classes from './ProductSection.module.css';

const productSection = (props) => {
    const productCarousel = props.loading ? <Spinner /> : <ProductCarousel items={3} products={props.products} currentLanguage={props.currentLanguage} />;
    return (
        <div className={classes.ProductSection}>
            {productCarousel}
        </div>
    );
};

export default productSection;